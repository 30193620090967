console.log("Hello World");

// arrow svg
// let paths = document.querySelectorAll('.arrow-svg');


// setInterval(() => {
//   paths?.forEach(path => {
//     length = path.getTotalLength();

//     path.style.transition = path.style.WebkitTransition = 'none';
//     path.style.strokeDasharray = length + ' ' + length;
//     path.style.strokeDashoffset = length;

//     path.getBoundingClientRect();

//     path.style.transition = path.style.WebkitTransition =
//         'stroke-dashoffset 1s ease-in-out';

//     path.style.strokeDashoffset = '0';

//   })
// }, 2000);


// end arrow svg

// ------------------------------------------ //

// accordion
const accordionBtns = document.querySelectorAll(".item-header");

accordionBtns?.forEach((accordion) => {
  accordion.onclick = function () {
    this.classList.toggle("active");

    let content = this.nextElementSibling;
    // console.log(content);

    if (content.style.maxHeight) {
      //this is if the accordion is open
      content.style.maxHeight = null;
    } else {
      //if the accordion is currently closed
      content.style.maxHeight = content.scrollHeight + "px";
      // console.log(content.style.maxHeight);
    }
  };
});
// end accordion

// mob menu
const menuShowBtn = document.querySelector("svg#menu-show");

menuShowBtn.addEventListener("click", () => {
  const mobileMenu = document.getElementById("menu");

  if (mobileMenu.classList.contains("show")) {
    mobileMenu.classList.remove("show");
    document.body.style.overflowY = "auto";
  } else {
    mobileMenu.classList.add("show");
    document.body.style.overflowY = "hidden";
  }
});
const navbarMobileItems = document.querySelectorAll(".navbar-mobile__item>a");

navbarMobileItems.forEach(function (e) {
  e.addEventListener("click", function () {
    (document.body.style.overflowY = "auto"),
      document.getElementById("menu").classList.remove("show");
    menuShowBtn.classList.remove("active");
  });
});

const linkOrder = document.querySelector(".link-order");

linkOrder.addEventListener("click", function () {
  (document.body.style.overflowY = "auto"),
    document.getElementById("menu").classList.remove("show");
  menuShowBtn.classList.remove("active");
})
// end


// walk header
const header = document.querySelector('header')
const headerDropdown = document.querySelector('.header-dropdown')

if(window.pageYOffset > 70) {
  header.classList.add('walk')
  headerDropdown.classList.add('walk')
} else {
  header.classList.remove('walk')
  headerDropdown.classList.remove('walk')
}

window.addEventListener('scroll', ()=> {
  if(window.pageYOffset > 70) {
    header.classList.add('walk')
    headerDropdown.classList.add('walk')
  } else {
    header.classList.remove('walk')
    headerDropdown.classList.remove('walk')
  }
})
// end walk header

// intlTelInput
let inputPhone = document.querySelector('#inputPhone'),
    validateOutput = document.querySelector('#validate-output'),
    iti = window.intlTelInput(inputPhone, {
      separateDialCode: !0,
      initialCountry: 'auto',
      geoIpLookup: function (e) {
        fetch(
          'https://api.ipdata.co/?api-key=9ab158fdd759d2971c57081673bc5391c5f0f7558c0f2f48d740ad3f'
        )
          .then(function (t) {
            return t.ok ? t.json() : e('')
          })
          .then(function (t) {
            e(t.country_code)
          })
      },
      utilsScript: 'assets/js/utils.js',
    })
  function handleChange() {
    let e = ''
    iti.isValidNumber()
      ? ((e = 'You number phone: ' + iti.getNumber() + ' is valid!'),
        (validateOutput.style.color = 'green'))
      : ((e = 'Please enter a valid number!'), (validateOutput.style.color = 'red'))
    let t = document.createTextNode(e)
    ;(validateOutput.innerHTML = ''), validateOutput.appendChild(t), validateOutput.style.display = 'block'
  }

  inputPhone.addEventListener('change', handleChange),
  inputPhone.addEventListener('keyup', handleChange),
  inputPhone.addEventListener('countrychange', e => {
      e.target.value = ''
    })

    // 2
    let inputPhone2 = document.querySelector('#inputPhone2'),
    validateOutput2 = document.querySelector('#validate-output2'),
    iti2 = window.intlTelInput(inputPhone2, {
      separateDialCode: !0,
      initialCountry: 'auto',
      geoIpLookup: function (e) {
        fetch(
          'https://api.ipdata.co/?api-key=9ab158fdd759d2971c57081673bc5391c5f0f7558c0f2f48d740ad3f'
        )
          .then(function (t) {
            return t.ok ? t.json() : e('')
          })
          .then(function (t) {
            e(t.country_code)
          })
      },
      utilsScript: 'assets/js/utils.js',
    })
  function handleChange2() {
    let e = ''
    iti2.isValidNumber()
      ? ((e = 'You number phone: ' + iti2.getNumber() + ' is valid!'),
        (validateOutput2.style.color = 'green'))
      : ((e = 'Please enter a valid number!'), (validateOutput2.style.color = 'red'))
    let t = document.createTextNode(e)
    ;(validateOutput2.innerHTML = ''), validateOutput2.appendChild(t), validateOutput2.style.display = 'block'
  }

  inputPhone2.addEventListener('change', handleChange2),
  inputPhone2.addEventListener('keyup', handleChange2),
  inputPhone2.addEventListener('countrychange', e => {
      e.target.value = ''
    })


// end intlTelInput

// form
const mainForm = document.querySelector('#main-form');
const greetForm = document.querySelector('#greet-form');

mainForm?.addEventListener('submit', async e => {
  e.preventDefault()
  const t = new FormData(e.target);
    let n = Object.fromEntries(t);
    let o = iti.getNumber();
    let r = iti.getSelectedCountryData();

   iti.isValidNumber()
    ? (
      fetch('MailService.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: n.name,
          email: n.email,
          type: n.type,
          phone: o,
          country: r.name,
          movingFrom: n.from,
          movingTo: n.to,
          movingDate: n.date,
          size: n.size,
          // utmSource: n.utm_source,
          // utmMedium: n.utm_medium,
          // utmCampaign: n.utm_campaign,
          // utmContent: n.utm_content,
          // utmTerm: n.utm_term,
        }),
      })
        .then(res => res.json())
        .then(data => {

          if (data.status == 'Ok') {
            document.querySelector('#validate-output').innerHTML = '';
            Swal.fire({
              title: "Success!",
              text: "Our manager will contact you shortly.",
              icon: "success"
            });
          } else {
            document.querySelector('#validate-output').innerHTML = '';

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Something went wrong :(`,
              footer: `Please try later.`,
            });
          }
          e.target.reset();
        })
        .catch(err => {
          console.log("MailService Error", err)

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Something went wrong :(`,
            footer: `Please try later.`,
          });

        }))
    : console.log('Please enter a valid number!');
});

// 2

greetForm?.addEventListener('submit', async e => {
  e.preventDefault()
  const t = new FormData(e.target);
    let n = Object.fromEntries(t);
    let o = iti2.getNumber();
    let r = iti2.getSelectedCountryData();

   iti2.isValidNumber()
    ? (
      fetch('MailService.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: n.name,
          email: n.email,
          type: n.type,
          phone: o,
          country: r.name,
          movingFrom: n.from,
          movingTo: n.to,
          movingDate: n.date,
          size: n.size,
          // utmSource: n.utm_source,
          // utmMedium: n.utm_medium,
          // utmCampaign: n.utm_campaign,
          // utmContent: n.utm_content,
          // utmTerm: n.utm_term,
        }),
      })
        .then(res => res.json())
        .then(data => {
          if (data.status == 'Ok') {
            document.querySelector('#validate-output2').innerHTML = '';
            Swal.fire({
              title: "Success!",
              text: "Our manager will contact you shortly.",
              icon: "success"
            });
          } else {
            document.querySelector('#validate-output2').innerHTML = '';

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Something went wrong :(`,
              footer: `Please try later.`,
            });
          }
          e.target.reset();
        })
        .catch(err => {
          console.log("MailService Error", err)

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Something went wrong :(`,
            footer: `Please try later.`,
          });
        }))
    : console.log('Please enter a valid number!');
});

// end form

// swiper slider codepen
if(window.innerWidth < 1200) {
  new Swiper(".swiper-container",{
      // direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 20,
      freeMode: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // nextButton: ".swiper-button-next",
      // prevButton: ".swiper-button-prev",
      paginationClickable: !0,
      // spaceBetween: 0,
      autoplay: 2500,
      loop: !0
  })
} else {
  new Swiper(".swiper-container",{
      // direction: "horizontal",
      slidesPerView: 1,
      parallax: !0,
      spaceBetween: 20,
      freeMode: true,
      navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
      },

      // nextButton: ".swiper-button-next",
      // prevButton: ".swiper-button-prev",
      paginationClickable: !0,
      // spaceBetween: 0,
      speed: 1500,
      parallax: !0,
      autoplay: 2500,
      loop: !0
  })
}

// let swiperPortfolio = new Swiper(".portfolioSwiper", {
//   slidesPerView: 4,
//   lazy: true,
//   mousewheel: true,
//   spaceBetween: 10,
//   breakpoints: {
//     1024: {
//       slidesPerView: 4,
//       spaceBetween: 10,
//     },
//     768: {
//       slidesPerView: 2,
//       spaceBetween: 10,
//     },
//     640: {
//       slidesPerView: 2,
//       spaceBetween: 10,
//     },
//     320: {
//       slidesPerView: 1,
//       spaceBetween: 10,
//     },
//   },
//   // navigation: {
//   //   nextEl: ".swiper-button-next",
//   //   prevEl: ".swiper-button-prev",
//   // },
//   pagination: {
//     el: ".swiper-pagination",
//     clickable: true,
//   },
// });

// swiper with thumbs
// 1
var thumbForSwiper1 = new Swiper(".thumbForSwiper1", {
  spaceBetween: 10,
  slidesPerView: 3,
  freeMode: true,
  watchSlidesProgress: true,

  preloadImages: false,
  lazy: true,
});
var mySwiper1 = new Swiper(".mySwiper1", {
  spaceBetween: 10,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  thumbs: {
    swiper: thumbForSwiper1,
  },
});


// 2
var thumbForSwiper2 = new Swiper(".thumbForSwiper2", {
  spaceBetween: 10,
  slidesPerView: 4,
  freeMode: true,
  watchSlidesProgress: true,

  preloadImages: false,
  lazy: true,
});
var mySwiper2 = new Swiper(".mySwiper2", {
  spaceBetween: 10,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  thumbs: {
    swiper: thumbForSwiper2,
  },
});

// 3
var thumbForSwiper3 = new Swiper(".thumbForSwiper3", {
  spaceBetween: 10,
  slidesPerView: 4,
  freeMode: true,
  watchSlidesProgress: true,

  preloadImages: false,
  lazy: true,
});
var mySwiper3 = new Swiper(".mySwiper3", {
  spaceBetween: 10,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  thumbs: {
    swiper: thumbForSwiper3,
  },
});

// 4
var thumbForSwiper4 = new Swiper(".thumbForSwiper4", {
  spaceBetween: 10,
  slidesPerView: 2,
  freeMode: true,
  watchSlidesProgress: true,

  preloadImages: false,
  lazy: true,
});
var mySwiper4 = new Swiper(".mySwiper4", {
  spaceBetween: 10,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  thumbs: {
    swiper: thumbForSwiper4,
  },
});

// 5
var thumbForSwiper5 = new Swiper(".thumbForSwiper5", {
  spaceBetween: 10,
  slidesPerView: 4,
  freeMode: true,
  watchSlidesProgress: true,

  preloadImages: false,
  lazy: true,
});
var mySwiper5 = new Swiper(".mySwiper5", {
  spaceBetween: 10,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  thumbs: {
    swiper: thumbForSwiper5,
  },
});

// 6
var thumbForSwiper6 = new Swiper(".thumbForSwiper6", {
  spaceBetween: 10,
  slidesPerView: 2,
  freeMode: true,
  watchSlidesProgress: true,

  preloadImages: false,
  lazy: true,
});
var mySwiper6 = new Swiper(".mySwiper6", {
  spaceBetween: 10,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  thumbs: {
    swiper: thumbForSwiper6,
  },
});

// 7
var thumbForSwiper7 = new Swiper(".thumbForSwiper7", {
  spaceBetween: 10,
  slidesPerView: 4,
  freeMode: true,
  watchSlidesProgress: true,

  preloadImages: false,
  lazy: true,
});
var mySwiper7 = new Swiper(".mySwiper7", {
  spaceBetween: 10,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  thumbs: {
    swiper: thumbForSwiper7,
  },
});


// end swiper with thumbs

// end


// scroll previous to portfolio after open and close gallery
// fsLightbox.props.onClose = function () {
//   document.location.href = "#portfolio";
// }
document.addEventListener("DOMContentLoaded", function () {

  if(fsLightboxInstances["first-lightbox"]) {

    fsLightboxInstances["first-lightbox"].props.onClose = function () {
      document.location.href = "#portfolio";
    }
    // fsLightboxInstances["second-lightbox"].props.onClose = function () {
    //   document.location.href = "#reviews";
    // }

  }

  // fsLightboxInstances["second-lightbox"].open()

})

// end scroll previous to portfolio after open and close gallery


// move items when mousemove
let currentUrl = window.location.href;

// console.log(currentUrl.includes("jobs"));

if (!currentUrl.includes("jobs")) {
  let aboutLines = document.querySelectorAll(".lines--about");
  let portfolioLines = document.querySelectorAll(".lines--portfolio");

  window.addEventListener("mousemove", function (e) {
    let x = e.clientX / window.innerWidth;
    let y = e.clientY / window.innerHeight;
    // aboutLines.style.transform = "translate(-" + x * 10 + "px, -" + y * 10 + "px)";

    // portfolioLines.style.transform = "translate(" + x * 10 + "px, " + y * 10 + "px)";

    aboutLines?.forEach((line) => {
      line.style.transform = "translate(" + x * 10 + "px, " + y * 10 + "px)";
    });

    portfolioLines?.forEach((line) => {
      line.style.transform = "translate(-" + x * 10 + "px, -" + y * 10 + "px)";
    });
  });
}
// end move items when mousemove


// portfolio
let nextButtons = document.querySelectorAll("#next");
let prevButtons = document.querySelectorAll("#prev");

nextButtons.forEach(function (item) {
  item.addEventListener("click", function (e) {
    // console.log(e.target.dataset.self);
    let self = e.target.dataset.self;
    let slide = e.target.dataset.slide;

    if (e.target.dataset.slide) {
      document.querySelector(`#${self}`).classList.remove("active");
      document.querySelector(`#${slide}`).classList.add("active");
    }

    // if (self == "next") {
    //   document.querySelector(`#${self}`).dataset.self = "prev";
    //   document.querySelector(`#${self}`).dataset.slide = "next";
    // } else {
    //   document.querySelector(`#${self}`).dataset.self = "next";
    //   document.querySelector(`#${self}`).dataset.slide = "prev";
    // }
  });
});

prevButtons.forEach(function (item) {
  item.addEventListener("click", function (e) {
    // console.log(e.target.dataset.self);
    let self = e.target.dataset.self;
    let slide = e.target.dataset.slide;

    if (e.target.dataset.slide) {
      document.querySelector(`#${self}`).classList.remove("active");
      document.querySelector(`#${slide}`).classList.add("active");
    }

    // if (self == "next") {
    //   document.querySelector(`#${self}`).dataset.self = "prev";
    //   document.querySelector(`#${self}`).dataset.slide = "next";
    // } else {
    //   document.querySelector(`#${self}`).dataset.self = "next";
    //   document.querySelector(`#${self}`).dataset.slide = "prev";
    // }
  });
});
// end portfolio







// ---------------------------------------------- //

// auto scroll to portfolio after scroll a page
// let element = document.querySelector('#portfolio');

// let Visible = function (target) {

//   let targetPosition = {
//       top: window.pageYOffset + target.getBoundingClientRect().top,
//       left: window.pageXOffset + target.getBoundingClientRect().left,
//       right: window.pageXOffset + target.getBoundingClientRect().right,
//       bottom: window.pageYOffset + target.getBoundingClientRect().bottom
//     },

//     windowPosition = {
//       top: window.pageYOffset,
//       left: window.pageXOffset,
//       right: window.pageXOffset + document.documentElement.clientWidth,
//       bottom: window.pageYOffset + document.documentElement.clientHeight
//     };

//   if (targetPosition.bottom > windowPosition.top &&
//     targetPosition.top < windowPosition.bottom &&
//     targetPosition.right > windowPosition.left &&
//     targetPosition.left < windowPosition.right) {
//     // console.clear();

//     // console.log('Вы видите элемент :)')
//       if(window.scrollY > (element.offsetTop - element.scrollHeight/2)) {
//         swiperPortfolio.slideNext()
//       } else {
//         swiperPortfolio.slidePrev()
//       }
//     // document.addEventListener('wheel', (evt)=>{
//     //   // console.log(evt);
//     //   if (evt.deltaY > 0) {
//     //     swiperPortfolio.slideNext()
//     //   } else {
//     //     swiperPortfolio.slidePrev()
//     //   }
//     //  });
//   } else {

//     // console.clear();
//     // console.log('Вы НЕ видите элемент :(');
//   };
// };



// function throttle( timeFrame) {
//   let lastTime = 0;
//   return function () {
//       const now = new Date();
//       if (now - lastTime >= timeFrame) {
//           Visible(element);
//           lastTime = now;
//       }
//   };
// }

// if (element) {
//   window.addEventListener('scroll', throttle(400));
// }








